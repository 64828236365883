* {
  font-family: 'Sukhumvit Set', 'Prompt', sans-serif;
}

body {
  font-size: 14px;
}

.hilight {
  color: #F15A22;
}

.App {}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

footer {
  text-align: center;
  font-size: 0.65em;
  padding-bottom: 15px;
}

@import './scss/Helper.scss';
@import './scss/Weekday.scss';
@import './scss/Round.scss';
@import './scss/Store.scss';