.searchbox {
    position: relative;

    input {
        padding-left: 40px;
        border: 1px solid #F15A22;
        border-radius: 20px;
    }

    i {
        position: absolute;
        top: 22px;
        left: 22px;
        color: #F15A22;
    }
}

.store-list {
    .store {
        padding: 5px;

        >a>div {
            border-radius: 6px;
            padding: 10px;
            flex: none;
            width: 100%;

            &::after {
                display: block;
                content: "";
                clear: both;
                float: none;
            }

            &.temple {
                background: #FFEED9;
            }

            &.hospital {
                background: #CAE9FF;
            }

            >* {
                float: left;
            }

            img {
                width: 40px;
            }

            .store-name {
                font-size: 0.9em;
                font-weight: bolder;
                line-height: 40px;
                padding-left: 15px;
            }
        }
    }
}