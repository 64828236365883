
/* Margin ============================ */
.m0 {
    margin: 0;
  }
  
  @for $i from 0 through 4 {
    $margin: $i * 5;
  
    .m#{$margin} {
      margin: $margin * 1px !important;
    }
  
    .mt#{$margin} {
      margin-top: $margin * 1px !important;
    }
  
    .mb#{$margin} {
      margin-bottom: $margin * 1px !important;
    }
  
    .ml#{$margin} {
      margin-left: $margin * 1px !important;
    }
  
    .mr#{$margin} {
      margin-right: $margin * 1px !important;
    }
  
    .mh#{$margin} {
      margin-left: $margin * 1px !important;
      margin-right: $margin * 1px !important;
    }
  
    .mv#{$margin} {
      margin-top: $margin * 1px !important;
      margin-bottom: $margin * 1px !important;
    }
  }
  
  /* Padding ============================ */
  .p0 {
    padding: 0;
  }
  
  @for $i from 0 through 4 {
    $padding: $i * 5;
  
    .p#{$padding} {
      padding: $padding * 1px !important;
    }
  
    .pt#{$padding} {
      padding-top: $padding * 1px !important;
    }
  
    .pb#{$padding} {
      padding-bottom: $padding * 1px !important;
    }
  
    .pl#{$padding} {
      padding-left: $padding * 1px !important;
    }
  
    .pr#{$padding} {
      padding-right: $padding * 1px !important;
    }
  
    .ph#{$padding} {
      padding-left: $padding * 1px !important;
      padding-right: $padding * 1px !important;
    }
  
    .pv#{$padding} {
      padding-top: $padding * 1px !important;
      padding-bottom: $padding * 1px !important;
    }
  }